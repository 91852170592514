// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Grid from "../vanillaui/atoms/Grid.re.mjs";
import * as Util from "../shared/Util.re.mjs";
import * as React from "react";
import * as Layout from "../shared/Layout.re.mjs";
import * as Lingui from "../../locales/Lingui.re.mjs";
import * as Router from "../shared/Router.re.mjs";
import * as RelayEnv from "../../entry/RelayEnv.re.mjs";
import * as Localized from "../shared/i18n/Localized.re.mjs";
import * as PageTitle from "../vanillaui/atoms/PageTitle.re.mjs";
import * as EventsList from "../organisms/EventsList.re.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as GlobalQuery from "../shared/GlobalQuery.re.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.re.mjs";
import * as WaitForMessages from "../shared/i18n/WaitForMessages.re.mjs";
import * as ReactRouterDom from "react-router-dom";
import * as JsxRuntime from "react/jsx-runtime";
import * as EventsQuery_graphql from "../../__generated__/EventsQuery_graphql.re.mjs";
import * as RescriptRelay_Query from "rescript-relay/src/RescriptRelay_Query.re.mjs";

import { css, cx } from '@linaria/core'
;

import { t } from '@lingui/macro'
;

var convertVariables = EventsQuery_graphql.Internal.convertVariables;

var convertResponse = EventsQuery_graphql.Internal.convertResponse;

RescriptRelay_Query.useQuery(convertVariables, EventsQuery_graphql.node, convertResponse);

RescriptRelay_Query.useLoader(convertVariables, EventsQuery_graphql.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(EventsQuery_graphql.node, convertResponse, (function (prim) {
        return prim;
      }));

RescriptRelay_Query.$$fetch(EventsQuery_graphql.node, convertResponse, convertVariables);

RescriptRelay_Query.fetchPromised(EventsQuery_graphql.node, convertResponse, convertVariables);

RescriptRelay_Query.retain(EventsQuery_graphql.node, convertVariables);

function Events(props) {
  var query = ReactRouterDom.useLoaderData();
  var match = usePreloaded(query.data);
  var fragmentRefs = match.fragmentRefs;
  var viewer = GlobalQuery.useViewer();
  return JsxRuntime.jsx(WaitForMessages.make, {
              children: (function () {
                  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
                              children: [
                                JsxRuntime.jsx(Layout.Container.make, {
                                      children: JsxRuntime.jsxs(Grid.make, {
                                            children: [
                                              JsxRuntime.jsx(PageTitle.make, {
                                                    children: t`all events`
                                                  }),
                                              JsxRuntime.jsxs("div", {
                                                    children: [
                                                      JsxRuntime.jsx(ReactRouterDom.Link, {
                                                            to: "/",
                                                            children: t`all`
                                                          }),
                                                      " ",
                                                      JsxRuntime.jsx("svg", {
                                                            children: JsxRuntime.jsx("circle", {
                                                                  cx: (1).toString(),
                                                                  cy: (1).toString(),
                                                                  r: (1).toString()
                                                                }),
                                                            className: "h-1.5 w-1.5 inline flex-none fill-gray-600",
                                                            viewBox: "0 0 2 2"
                                                          }),
                                                      " ",
                                                      JsxRuntime.jsx(ReactRouterDom.Link, {
                                                            to: {
                                                              pathname: "",
                                                              search: ReactRouterDom.createSearchParams({
                                                                      activity: "pickleball"
                                                                    }).toString()
                                                            },
                                                            children: t`pickleball`
                                                          }),
                                                      " ",
                                                      JsxRuntime.jsx("svg", {
                                                            children: JsxRuntime.jsx("circle", {
                                                                  cx: (1).toString(),
                                                                  cy: (1).toString(),
                                                                  r: (1).toString()
                                                                }),
                                                            className: "h-1.5 w-1.5 inline flex-none fill-gray-600",
                                                            viewBox: "0 0 2 2"
                                                          }),
                                                      " ",
                                                      JsxRuntime.jsx(ReactRouterDom.Link, {
                                                            to: {
                                                              pathname: "",
                                                              search: ReactRouterDom.createSearchParams({
                                                                      activity: "badminton"
                                                                    }).toString()
                                                            },
                                                            children: t`badminton`
                                                          }),
                                                      Core__Option.getOr(Core__Option.map(viewer.user, (function (param) {
                                                                  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                                                              children: [
                                                                                " ",
                                                                                JsxRuntime.jsx("svg", {
                                                                                      children: JsxRuntime.jsx("circle", {
                                                                                            cx: (1).toString(),
                                                                                            cy: (1).toString(),
                                                                                            r: (1).toString()
                                                                                          }),
                                                                                      className: "h-1.5 w-1.5 inline flex-none fill-gray-600",
                                                                                      viewBox: "0 0 2 2"
                                                                                    }),
                                                                                " ",
                                                                                JsxRuntime.jsx(ReactRouterDom.Link, {
                                                                                      to: "/events",
                                                                                      children: t`my events`,
                                                                                      relative: "path"
                                                                                    })
                                                                              ]
                                                                            });
                                                                })), null)
                                                    ]
                                                  })
                                            ]
                                          })
                                    }),
                                JsxRuntime.jsx(React.Suspense, {
                                      children: Caml_option.some(JsxRuntime.jsx(EventsList.make, {
                                                events: fragmentRefs
                                              })),
                                      fallback: Caml_option.some(JsxRuntime.jsx(Layout.Container.make, {
                                                children: t`loading events...`
                                              }))
                                    })
                              ]
                            });
                })
            });
}

var LoaderArgs = {};

function loadMessages(lang) {
  var tmp = lang === "ja" ? import("../../locales/src/components/pages/Events.re/ja") : import("../../locales/src/components/pages/Events.re/en");
  return [tmp.then(function (messages) {
                React.startTransition(function () {
                      Lingui.i18n.load(lang, messages.messages);
                    });
              })];
}

async function loader(param) {
  var params = param.params;
  var url = new URL(param.request.url);
  var after = Router.SearchParams.get(url.searchParams, "after");
  var before = Router.SearchParams.get(url.searchParams, "before");
  var activity = Router.SearchParams.get(url.searchParams, "activity");
  var afterDate = Core__Option.getOr(Core__Option.map(Router.SearchParams.get(url.searchParams, "afterDate"), (function (d) {
              return Util.Datetime.fromDate(new Date(d));
            })), Util.Datetime.fromDate(new Date()));
  if (import.meta.env.SSR) {
    await Localized.loadMessages(params.lang, loadMessages);
  }
  return {
          data: EventsQuery_graphql.load(RelayEnv.getRelayEnv(param.context, import.meta.env.SSR), {
                after: after,
                afterDate: Caml_option.some(afterDate),
                before: before,
                filters: {
                  activitySlug: activity
                }
              }, "store-or-network", undefined, undefined),
          i18nLoaders: import.meta.env.SSR ? undefined : Caml_option.some(Localized.loadMessages(params.lang, loadMessages))
        };
}

var make = Events;

var Component = Events;

export {
  make ,
  Component ,
  LoaderArgs ,
  loader ,
}
/*  Not a pure module */
